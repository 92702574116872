import { GetMicrosite } from 'data/global/features/MicrositeSlice/micrositeSlice';
import { useAppDispatch } from 'data/global/hooks';

export const useGetMicrosite = () => {
	const dispatch = useAppDispatch();

	const getMicrosite = (getMicrosite: any): void => {
		dispatch(GetMicrosite(getMicrosite));
	};

	return getMicrosite;
};
export default useGetMicrosite;
