import React from 'react';
import FooterView from './view';

type props = {
	className?: string;
};

export const Footer: React.FC<props> = ({ className }) => {
	return <FooterView className={className} />;
};
