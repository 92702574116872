import { FunctionComponent, useEffect, useState } from 'react';
import { NavBarHome, Footer, Card, Slider } from 'components';
import {
	GetHighlightProductResponse,
	ProductHighLigth,
} from 'data/types/productsTypes';
import { GetBannersResponse } from 'data/types/bannersTypes';
import { GetBrandsResponse } from 'data/types/brandsTypes';
import { GetCategoriesResponse } from 'data/types/categoriesTypes';
import useStyles from './useStyle';
import { TMicrosite } from 'models/microsite';
import { MicrositeResponse } from 'data/types/microsite';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type Props = {
	brands: GetBrandsResponse | null;
	products: GetHighlightProductResponse | null;
	categories: GetCategoriesResponse | null;
	banners: GetBannersResponse | null;
	settingSliderBrand: Object;
	settingSliderProductsHighlight: Object;
	login: string | null;
	getMicrosite: (microsite: TMicrosite) => void;
	micrositeDetails: MicrositeResponse | null;
};
export const ViewFourWitch: FunctionComponent<Props> = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (
			props.micrositeDetails &&
			props.micrositeDetails.featuredProducts &&
			props.micrositeDetails.banner
		) {
			setIsLoading(false);
		}
	}, [props.micrositeDetails]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (isLoading) {
				navigate('/');
			}
		}, 3000);

		return () => clearTimeout(timer);
	}, [isLoading, navigate]);

	if (isLoading) {
		return <div>Cargando Micrositio...</div>;
	}
	const bannerImage = props.micrositeDetails?.banner || '';

	const backgroundColor = {
		backgroundColor: props.micrositeDetails?.color || '',
	};

	const featuredProducts = props.micrositeDetails?.featuredProducts;

	return (
		<>
			<div className={classes.container}>
				<NavBarHome />
				<div className={classes.containerImg} style={backgroundColor}>
					<img className={classes.img} src={bannerImage} />
				</div>
				<div style={{ padding: '90px' }}>
					{featuredProducts && featuredProducts.length > 0 && (
						<Grid item xs={10} mb={4}>
							<Slider
								title='Productos destacados'
								settings={props.settingSliderProductsHighlight}
							>
								{featuredProducts &&
									featuredProducts.map((product: ProductHighLigth) => {
										return (
											<Grid
												item
												xs={6}
												sm={4}
												md={6}
												lg={3}
												key={product.masterProductFrontend.masterProductId}
											>
												<Card
													catalogProduct={
														product?.catalogProductFrontendList?.[0]
													}
													masterPodruct={product?.masterProductFrontend}
												/>
											</Grid>
										);
									})}
							</Slider>
						</Grid>
					)}
				</div>

				<Footer className={classes.containerFooter} />
			</div>
		</>
	);
};
