import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import { Either, isRight, unwrapEither } from 'models/either';
import getAllMicrositesApi from 'data/api/allMicrosites';

type TReallMicrositesSlice = {
	allMicrositeStatusRequest: StatusRequest;
	allMicrositeSResponse?: any;
	allMicrositeSStatusError?: Failure;
	allMicrositeSDetails?: any;
	allMicrositeSDetailsError?: Failure;
};

const initialState: TReallMicrositesSlice = {
	allMicrositeStatusRequest: StatusRequest.initial,
	allMicrositeSResponse: undefined,
	allMicrositeSStatusError: undefined,
	allMicrositeSDetails: {},
	allMicrositeSDetailsError: undefined,
};

export const GetAllMicrositesAsync = createAsyncThunk<
	any,
	any,
	{ rejectValue: Failure }
>('/GetAllMicrositesAsync', async (request: any, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, any> =
			await getAllMicrositesApi.getAllMicrosites();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const AllMicrositesSlice = createSlice({
	name: 'allMicrosites',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(GetAllMicrositesAsync.pending, (state) => {
			state.allMicrositeStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllMicrositesAsync.fulfilled, (state, action) => {
			state.allMicrositeStatusRequest = StatusRequest.fulfilled;
			state.allMicrositeSResponse = action.payload;
		});
		builder.addCase(GetAllMicrositesAsync.rejected, (state, action) => {
			state.allMicrositeStatusRequest = StatusRequest.rejected;
			state.allMicrositeSDetailsError = action.payload;
		});
	},
});

export const selectAllMicrosites = (state: RootState) => state.allMicrosites;
export const {} = AllMicrositesSlice.actions;
export default AllMicrositesSlice.reducer;
