/* eslint-disable jsx-a11y/anchor-has-content */
import { FunctionComponent, Fragment } from 'react';
import {
	NavBarHome,
	Slider,
	Card,
	HomeLayout,
	CategoriesVisited,
	Footer,
	CartButton,
} from 'components';
import { Box, Grid, Paper, Typography } from '@mui/material';
import {
	GetHighlightProductResponse,
	ProductHighLigth,
} from 'data/types/productsTypes';
import { Brand, GetBrandsResponse } from 'data/types/brandsTypes';
import { Category, GetCategoriesResponse } from 'data/types/categoriesTypes';
import { GetBannersResponse } from 'data/types/bannersTypes';
import { Button } from 'UI';
import { Link } from 'react-router-dom';
import CintaHome from 'fonts/CintaHome.svg';
import useStyles from './useStyles';
import { CatalogProduct } from 'data/types/catalogTypes';

type Props = {
	brands: GetBrandsResponse | null;
	products: GetHighlightProductResponse | null;
	categories: GetCategoriesResponse | null;
	banners: GetBannersResponse | null;
	settingSliderBrand: Object;
	settingSliderProductsHighlight: Object;
	login: string | null;
	allMicrosites: any;
};

let value;
export const ViewHome: FunctionComponent<Props> = (props) => {
	const classes = useStyles();
	return (
		<Fragment>
			<Box sx={{ backgroundColor: '#F7F5F7' }}>
				<NavBarHome></NavBarHome>
				<Grid container display='flex' justifyContent='center'>
					<Grid
						item
						xs={12}
						display='flex'
						justifyContent='end'
						mr='20px'
						pt={2}
						pb={2}
					>
						<CartButton />
					</Grid>
					{props.login === null ? (
						<Grid
							item
							xs={12}
							sx={{ backgroundColor: '#5C41BD' }}
							height={'400px'}
						>
							<Box
								display='flex'
								flexDirection={'row'}
								justifyContent='space-between'
								alignItems={'center'}
								ml={16}
								mt={10}
							>
								<Typography
									fontFamily='Krub'
									fontSize={40}
									color='#FFFFFF'
									width={'464px'}
									mb={4}
									fontWeight='bold'
									lineHeight={1.2}
									ml={'100px'}
								>
									Los mejores beneficios para tu librería
								</Typography>
								<Box mr={25}>
									<Link
										to={{ pathname: '/register' }}
										state={{ tabIndex: 0 }}
										color='inherit'
										style={{ color: 'inherit', textDecoration: 'inherit' }}
									>
										<Button
											variant='contained'
											sx={{
												borderRadius: '20px',
												backgroundColor: '#FF6651',
												width: '264px',
												height: '42px',
												marginRight: '30px',
											}}
										>
											<Typography
												fontFamily='Krub'
												fontSize={14}
												color='#FFFFFF'
												fontWeight='bold'
											>
												Creá una cuenta ahora
											</Typography>
										</Button>
									</Link>
									<Link
										to={{ pathname: '/register' }}
										state={{ tabIndex: 1 }}
										color='inherit'
										style={{ color: 'inherit', textDecoration: 'inherit' }}
									>
										<Button
											variant='contained'
											sx={{
												borderRadius: '20px',
												backgroundColor: '#FFFFFF',
												width: '264px',
												height: '42px',
											}}
										>
											<Typography
												fontFamily='Krub'
												fontSize={14}
												color='#3D363C'
												fontWeight='bold'
											>
												Iniciá sesión
											</Typography>
										</Button>
									</Link>
								</Box>
							</Box>
							<Typography
								display='flex'
								flexDirection={'row'}
								alignItems={'center'}
								fontFamily='Krub'
								fontSize={18}
								color='#E8E3FA;'
								ml={'232px'}
								fontWeight='medium'
							>
								Quiero saber más
								<i
									className='icon-arrow-downward'
									style={{ marginLeft: '10px' }}
								></i>
							</Typography>
						</Grid>
					) : (
						<></>
					)}
					<Grid item xs={10} mt={props.login === null ? -12 : 6} mb={6}>
						{props.banners && (
							<a
								href={props.banners[0].link}
								style={{ textDecoration: 'none' }}
							>
								<img
									src={props.banners[0].image}
									alt='banner'
									width='100%'
								></img>
							</a>
						)}
					</Grid>
					<Grid item xs={10} mb={4}>
						<Slider
							title='Comprá por marca'
							settings={props.settingSliderBrand}
						>
							{props.brands &&
								props.brands.map((brands: Brand) => {
									return (
										<div key={brands.brandID}>
											<a
												href={`/catalog?brand=${brands.brandID}`}
												style={{ textDecoration: 'none' }}
											>
												<Paper
													elevation={0}
													sx={{
														width: '140px',
														height: '140px',
														marginRigth: '10px',
													}}
												>
													<img
														src={brands.brandLogo}
														width='140px'
														height='140px'
														alt='brand'
													></img>
												</Paper>
											</a>
										</div>
									);
								})}
						</Slider>
					</Grid>
					<Grid item xs={10} mb={4}>
						<Slider
							title='Productos destacados'
							settings={props.settingSliderProductsHighlight}
						>
							{props.products &&
								props.products.map((product: ProductHighLigth) => {
									// return product.map((catalogProduct: CatalogProduct) => {
									return (
										<Grid
											item
											xs={6}
											sm={4}
											md={6}
											lg={3}
											key={product.masterProductFrontend.masterProductId}
										>
											<Card
												catalogProduct={product.catalogProductFrontendList[0]}
												masterPodruct={product.masterProductFrontend}
											/>
										</Grid>
									);
									// });
								})}
						</Slider>
					</Grid>

					<Grid
						container
						justifyContent='space-around'
						mt={5}
						pb={props.login ? 20 : 3}
						pt={10}
						sx={{
							backgroundColor: '#FF6651',
							backgroundImage: `url(${CintaHome})`,
							backgroundPosition: 'left',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							height: '100%',
							width: '100%',
						}}
					>
						<HomeLayout login={props.login} />
					</Grid>
					<div style={{ display: 'flex' }}>
						<div className={classes.allMicrosites}>
							<h2 className={classes.title}>Micrositios</h2>
							<div className={classes.mainContainer}>
								{props.allMicrosites &&
									props.allMicrosites.map((microsite: any) => (
										<a
											className={classes.microsite}
											href={`/distribuidores/${microsite?.name}`}
											style={{ textDecoration: 'none' }}
										>
											<h2 className={classes.name}>{microsite?.name}</h2>
											<img className={classes.banner} src={microsite?.banner} />
										</a>
									))}
							</div>
						</div>
					</div>
					<Grid
						container
						style={{ backgroundColor: 'white' }}
						pb={10}
						pt={5}
						mb={2}
					>
						<Grid item display='flex' justifyContent='center' xs={4}>
							<Typography
								fontFamily='Krub'
								fontSize={30}
								color='#5C41BD'
								fontWeight='normal'
								mb={4}
							>
								Categorías más visitadas
							</Typography>
						</Grid>
						<Grid
							item
							mb={6}
							display='flex'
							flexDirection='row'
							justifyContent='space-around'
							xs={12}
						>
							{props.categories &&
								props.categories.slice(0, 5).map((category: Category) => {
									return (
										<a
											href={`/catalog?category=${category.categoryID}`}
											style={{ textDecoration: 'none' }}
										>
											<div key={category.categoryID}>
												<CategoriesVisited category={category} />
											</div>
										</a>
									);
								})}
						</Grid>
					</Grid>
					<Grid item xs={12} mt={2}>
						<Footer />
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	);
};
