import { Failure } from 'data/types';
import {
	GetMicrositeRequest,
	GetMicrositeResponse,
} from 'data/types/microsite';
import { Either } from 'models/either';
import * as BaseRepository from 'data/models/baseRepository';
import * as BaseApi from 'data/models/baseApi';

export const getMicrosite = async (
	name: string
): Promise<Either<Failure, GetMicrositeResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetMicrositeRequest, GetMicrositeResponse>({
			url: `/api/public/v1/microsite/${name}`,
			secure: false,
		})
	);
};

const MicrositeApi = {
	getMicrosite,
};

export default MicrositeApi;
