import {
	GetBannersAsync,
	selectBanners,
} from 'data/global/features/BannersSlice/bannersSlice';
import {
	GetBrandsAsync,
	selectBrands,
} from 'data/global/features/BrandsSlice/brandsSlice';
import {
	GetCategoriesAsync,
	selectCategories,
} from 'data/global/features/CategoriesSlice/categoriesSlice';
import {
	GetHighlightProductAsync,
	GetProductsAsync,
	selectProducts,
} from 'data/global/features/ProductsSlice/productsSlice';
import { useAppSelector, useAppDispatch } from 'data/global/hooks';
import React, { useEffect, useState } from 'react';
import { ViewHome } from './view';
import { useValidationUser } from 'components/RightSectionRegisterLibrary/hooks';
import {
	GetAllMicrositesAsync,
	selectAllMicrosites,
} from 'data/global/features/AllMicrositesSlice/allMicrositesSlice';

export const Home: React.FC = () => {
	const JWT = localStorage.getItem('jwt');

	const validationUser = useValidationUser();

	const settingsSliderBrands = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 7,
		slidesToScroll: 7,
		arrows: false,
		responsive: [
			{
				breakpoint: 1220,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
		],
	};
	const settingSliderProductsHighlight = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: false,
		responsive: [
			{
				breakpoint: 1220,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const code = queryParams.get('code');

		if (code) {
			validationUser({ code });
		}
	}, [validationUser]);

	useEffect(() => {
		dispatch(GetHighlightProductAsync({}));
		dispatch(GetBrandsAsync({ showInHome: true }));
		dispatch(GetCategoriesAsync({}));
		dispatch(GetBannersAsync({}));
		dispatch(GetAllMicrositesAsync({}));
	}, []);

	const { GetHighlightProductResponseRequest } = useAppSelector(selectProducts);
	const { brands } = useAppSelector(selectBrands);
	const { categories } = useAppSelector(selectCategories);
	const { banners } = useAppSelector(selectBanners);
	const { allMicrositeSResponse } = useAppSelector(selectAllMicrosites);

	console.log({ GetHighlightProductResponseRequest });
	console.log({ brands });
	console.log({ categories });
	console.log({ banners });

	return (
		<ViewHome
			banners={banners}
			brands={brands}
			products={GetHighlightProductResponseRequest}
			categories={categories}
			settingSliderBrand={settingsSliderBrands}
			settingSliderProductsHighlight={settingSliderProductsHighlight}
			allMicrosites={allMicrositeSResponse}
			login={JWT}
		/>
	);
};
