import * as pages from 'pages';
import namesRoutes from 'routes/namesRoutes';
import { ResetPasswordView } from '../pages/ResetPassword/view';
import { ResetPassword } from '../pages/ResetPassword';

const routes = [
	{
		path: namesRoutes.registerLibrary,
		component: pages.RegisterLibrary,
	},
	{
		path: namesRoutes.signIn,
		component: pages.SignIn,
	},
	{
		path: namesRoutes.register,
		component: pages.Register,
	},
	{
		path: namesRoutes.registerDistributors,
		component: pages.RegisterDistributors,
	},
	{
		path: namesRoutes.home,
		component: pages.Home,
	},
	{
		path: namesRoutes.catalog,
		component: pages.Catalog,
	},
	{
		path: namesRoutes.cart,
		component: pages.Cart,
	},
	{
		path: namesRoutes.viewItem,
		component: pages.ViewItem,
	},
	{
		path: namesRoutes.orders,
		component: pages.Orders,
	},
	{
		path: namesRoutes.orderDetail,
		component: pages.OrderDetail,
	},
	{
		path: namesRoutes.aboutDeUtiles,
		component: pages.AboutDeUtiles,
	},
	{
		path: namesRoutes.faq,
		component: pages.FaQ,
	},
	{
		path: namesRoutes.termsandconditions,
		component: pages.TermsAndConditions,
	},
	{
		path: namesRoutes.privacyPolicies,
		component: pages.PrivacyPolicies,
	},
	{
		path: namesRoutes.recoverPassword,
		component: pages.PasswordRecovery,
	},
	{
		path: namesRoutes.resetPassword,
		component: pages.ResetPassword,
	},
	{
		path: namesRoutes.changePasswordSuccessfully,
		component: pages.ChangePasswordSuccesfully,
	},

	{
		path: namesRoutes.fourwitch,
		component: pages.FourWitch,
	},
];

export default routes;
