import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from 'data/global/features/AuthSlice/authSlice';
import userReducer from 'data/global/features/UserSlice/userSlice';
import libraryReducer from 'data/global/features/LibrarySlice/librarySlice';
import distributorReducer from 'data/global/features/DistributorSlice/distributorSlice';
import bannersReducer from 'data/global/features/BannersSlice/bannersSlice';
import brandsReducer from 'data/global/features/BrandsSlice/brandsSlice';
import cartReducer from 'data/global/features/CartSlice/cartSlice';
import catalogReducer from 'data/global/features/CatalogSlice/catalogSlice';
import categoriesReducer from 'data/global/features/CategoriesSlice/categoriesSlice';
import productsReducer from 'data/global/features/ProductsSlice/productsSlice';
import orderReducer from 'data/global/features/OrdersSlice/ordersSlice';
import micrositeReducer from 'data/global/features/MicrositeSlice/micrositeSlice';
import allMicrositesReducer from 'data/global/features/AllMicrositesSlice/allMicrositesSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		library: libraryReducer,
		distributor: distributorReducer,
		banners: bannersReducer,
		brands: brandsReducer,
		cart: cartReducer,
		catalog: catalogReducer,
		categories: categoriesReducer,
		products: productsReducer,
		orders: orderReducer,
		microsite: micrositeReducer,
		allMicrosites: allMicrositesReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
