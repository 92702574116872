import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MicrositeApi from 'data/api/microsite';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import {
	GetMicrositeRequest,
	GetMicrositeResponse,
	MicrositeResponse,
} from 'data/types/microsite';
import { Either, isRight, unwrapEither } from 'models/either';

type TRemicrositeSlice = {
	micrositeStatusRequest: StatusRequest;
	micrositeResponse?: MicrositeResponse;
	micrositeStatusError?: Failure;
	micrositeDetails?: any;
	micrositeDetailsError?: Failure;
};

const initialState: TRemicrositeSlice = {
	micrositeStatusRequest: StatusRequest.initial,
	micrositeResponse: undefined,
	micrositeStatusError: undefined,
	micrositeDetails: {},
	micrositeDetailsError: undefined,
};

export const GetMicrosite = createAsyncThunk<
	GetMicrositeResponse,
	GetMicrositeRequest,
	{ rejectValue: Failure }
>(
	'microsite/GetMicrosite',
	async (request: GetMicrositeRequest | any, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, GetMicrositeResponse> =
				await MicrositeApi.getMicrosite(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const MicrositeSlice = createSlice({
	name: 'microsite',
	initialState,
	reducers: {
		resetMicrositeStatusRequest: (state) => {
			state.micrositeStatusRequest = 0;
		},
		resetMicrositeDetailsRequest: (state) => {
			state.micrositeDetails = undefined;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetMicrosite.pending, (state) => {
			state.micrositeStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetMicrosite.fulfilled, (state, action) => {
			state.micrositeDetails = action.payload;
		});
		builder.addCase(GetMicrosite.rejected, (state, action) => {
			state.micrositeDetailsError = action.payload;
		});
	},
});

export const selectMicrosite = (state: RootState) => state.microsite;
export const { resetMicrositeStatusRequest } = MicrositeSlice.actions;
export default MicrositeSlice.reducer;
