import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	title: {
		color: '#5C41BD',
		marginLeft: '30px',
		marginBottom: 'unset',
		fontWeight: '100',
	},
	name: {
		fontSize: '15px',
		fontFamily: 'monospace',
		color: 'black',
	},
	banner: {
		width: '100px',
	},
	microsite: {
		marginLeft: '30px',
	},
	allMicrosites: {
		display: 'flex',
		alignItems: 'baseline',
		marginBottom: '40px',
	},
}));
export default useStyles;
