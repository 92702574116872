import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	ejemplo: {
		minHeight: 'calc(100vh - 400px)',
	},
	header: {
		padding: '5vh 1vw',
		paddingBottom: '0px',
		background: 'white',
		display: 'flex',
	},
	aside: {
		maxWidth: '320px',
		width: '35vw',
		fontSize: '35px',
		fontWeight: 'bold',
		padding: '15px',
		fontFamily: 'Krub',
	},
	headerSpan: {
		fontSize: '16px',
		color: '#FF6651',
		fontFamily: 'Krub',
	},
	nav: {
		display: 'flex',
		background: '#9179E8',
		marginLeft: '1vw',
		'&:last-child': {
			marginRight: '0px',
		},
	},
	nav2: {
		display: 'flex',
		background: '#9179E8',
		'&:last-child': {
			marginRight: '0px',
		},
	},
	navCtrl: {
		maxWidth: '165px',
		width: '14vw',
		padding: '15px',
		color: 'white',
		marginRight: '0.5vw',
		background: '#5C41BD',
		fontFamily: 'Krub',
		fontSize: '16px',
	},
	row: {
		display: 'flex',
		padding: '0px 1vw',
		paddingTop: '1vh',
	},
	rAside: {
		maxWidth: '320px',
		width: '35vw',
		alignItems: 'center',
		marginRight: '1vw',
		// padding: '0px 15px',
		display: 'flex',
	},
	rContent: {
		display: 'flex',
	},
	rCtrl: {
		maxWidth: '165px',
		width: '14vw',
		marginRight: '0.5vw',
		fontFamily: 'Krub',
		fontSize: '20px',
		color: '3D363C',
		fontWeight: 'bold',
		// maxWidth: '100px',
	},
	rCtrl3: {
		width: '9vw',
		marginLeft: '16px',
		fontFamily: 'Krub',
		fontSize: '20px',
		color: '3D363C',
		fontWeight: 'bold',
	},
	rCtrlL: {
		width: '14vw',
	},
	image: {
		width: '50px',
		height: '50px',
	},
	description: {
		width: '50%',
		marginLeft: '5px',
		fontFamily: 'Krub',
		fontSize: '16px',
		cursor: 'pointer',
	},
	actions: {
		width: '40%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#5c41bd',
	},
	number: {
		width: '4vw',
		margin: '0px 1vw',
		textAlign: 'center',
		borderBottom: '2px dotted',
	},
	rSection: {
		paddingBottom: '1vh',
	},
	footer: {
		padding: '0px 1vw',
		display: 'flex',
		background: 'white',
	},
	fAside: {
		maxWidth: '320px',
		width: '35vw',
		padding: '15px',
		fontWeight: 'bold',
		marginRight: '1vw',
		// maxWidth: '300px',
	},
	dTotal: {
		color: '#5c41bd',
		fontSize: '21px',
		fontFamily: 'Krub',
	},
	dDelivery: {
		color: '#27C451',
		fontFamily: 'Krub',
		fontSize: '16px',
	},
	fNav: {
		display: 'flex',
		background: '#9179e8',
	},
	fNavCtrl: {
		maxWidth: '165px',
		width: '14vw',
		marginRight: '0.5vw',
		background: '#5C41BD',
		color: 'white',
	},
	fHeader: {
		background: '#432695',
		padding: '0.8vh 1vw',
	},
	fSwitch: {
		width: '100%',
		justifyContent: 'space-around',
		background: '#9179E8',
		borderRadius: '15px',
		display: 'flex',
		padding: '2px 0px',
		position: 'relative',
		zIndex: 1,
		'&::before': {
			transition: 'left 0.3s',
			left: '0px',
			width: '50%',
			top: 0,
			content: '""',
			display: 'block',
			position: 'absolute',
			background: 'white',
			height: '100%',
			borderRadius: '16px',
			zIndex: '-1',
			boxShadow: '0px 0px 5px #00000040',
		},
	},
	fDelivery: {
		'&::before': {
			left: '50%',
		},
	},
	price: {
		display: 'block',
		textAlign: 'right',
		padding: '1vh 1vw',
		paddingBottom: '3px',
		fontWeight: 'bold',
		fontFamily: 'Krub',
		fontSize: '18px',
	},
	scrollButton: {
		position: 'fixed',
		borderRadius: '100%',
		right: '50px',
		top: '50%',
		height: '30px',
		width: '30px',
		color: 'white',
		placeItems: 'center',
		fontSize: '20px',
		cursor: 'pointer',
		backgroundColor: '#FF6651',
	},
	saving: {
		display: 'block',
		padding: '1vh 1vw',
		textAlign: 'right',
		paddingTop: '0px',
		color: '#27C451',
		fontWeight: 'bold',
		fontFamily: 'Krub',
		fontSize: '18px',
	},
	fSBtn: {
		color: '#432695',
		textTransform: 'uppercase',
		width: '100%',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '12px',
		lineHeight: '24px',
		cursor: 'pointer',
		fontFamily: 'Krub',
	},
	realFooter: {
		width: '100vw',
		maxWidth: '1920px',
		position: 'sticky',
		left: '0',
		background: '#FF6651',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '2vh 4vw',
	},
	rFButton: {
		color: '#FFFFFF',
		border: '1px solid #FFFFFF',
		background: 'transparent',
		fontSize: '16px',
		fontWeight: 'bold',
		padding: '1.5vh 1.5vw',
		borderRadius: '31px',
		fontFamily: 'Krub',
	},
	rFAside: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'white',
		width: '30vw',
		borderRadius: '50px',
		alignItems: 'center',
		padding: '0px 2vw',
		color: '#5C41BD',
		cursor: 'pointer',
		transition: 'backgroundColor 0.3s, color 0.3s',
		'&:hover': {
			color: 'white',
			backgroundColor: '#3D363C',
		},
	},
	rFGO: {
		fontWeight: 'bold',
		fontFamily: 'Krub',
		fontSize: '16px',
	},
	rFTag: {
		margin: '0px 5px',
		fontWeight: 'bold',
		fontFamily: 'Krub',
		fontSize: '20px',
	},
	rFCTCTVLASDOQWEPPPP: {
		display: 'flex',
		alignItems: 'baseline',
	},
	rFPrice: {
		fontWeight: 'bold',
		fontSize: '20px',
		fontFamily: 'Krub',
	},
	loading: {
		padding: '2vw',
		background: 'linear-gradient(57deg, #ff6651, #5c41bd)',
		borderRadius: '1vw',
		margin: '2vw',
		textAlign: 'center',
		color: 'white',
		fontSize: '1.5vw',
		boxShadow: '0px 0px 15px #c9c9c9',
	},
	cardAside: {
		top: '0px',
		right: '0px',
		zIndex: 1,
		position: 'absolute',
		width: '50px',
		textAlign: 'right',
		color: 'white',
		height: '55px',
		overflow: 'hidden',
		lineHeight: 1,
		'&::after': {
			top: '-25px',
			right: '-25px',
			width: '50px',
			height: '50px',
			content: '""',
			display: 'block',
			zIndex: '-1',
			position: 'absolute',
			transform: 'rotate(45deg)',
			background: '#5C41BD',
		},
	},
}));
export default useStyles;
