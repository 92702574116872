import { FunctionComponent, Fragment } from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import logo from 'fonts/logo.svg';
import yoplabs from 'fonts/yoplabs.svg';

type Props = {
	className?: string;
};

const FooterView: FunctionComponent<Props> = ({ className }) => (
	<Fragment>
		<Grid
			container
			display='flex'
			justifyContent='space-around'
			className={className}
			sx={{ backgroundColor: '#F7F5F7' }}
		>
			<Grid item xs={2}>
				<img
					src={logo}
					alt='logo'
					style={{
						width: '158px',
						height: '35px',
					}}
				></img>
			</Grid>
			<Grid item xs={1.5} mb={4}>
				<Box mb={6}>
					<Link
						href='/aboutDeUtiles'
						color='inherit'
						underline='none'
						style={{ cursor: 'pointer' }}
					>
						<Typography
							fontFamily='Krub'
							fontSize='18px'
							fontWeight='bold'
							color='#3D363C'
							mb={1}
						>
							Sobre deÚtiles
						</Typography>
					</Link>
					<Link
						href='/faq'
						color='inherit'
						underline='none'
						style={{ cursor: 'pointer' }}
					>
						<Typography
							fontFamily='Krub'
							fontSize='18px'
							fontWeight='bold'
							color='#3D363C'
							mb={1}
						>
							Preguntas frecuentes
						</Typography>
					</Link>
					<a
						href='mailto:info@deutiles.com'
						style={{ cursor: 'pointer', textDecoration: 'none' }}
					>
						<Typography
							fontFamily='Krub'
							fontSize='18px'
							fontWeight='bold'
							color='#3D363C'
							mb={1}
						>
							Contacto
						</Typography>
					</a>
				</Box>
				<Link
					href='/termsandconditions'
					color='inherit'
					underline='none'
					style={{ cursor: 'pointer' }}
				>
					<Typography fontFamily='Krub' fontSize='16px' color='#5C41BD' mb={1}>
						Términos y condiciones
					</Typography>
				</Link>
				<Link
					href='/privacyPolicies'
					color='inherit'
					underline='none'
					style={{ cursor: 'pointer' }}
				>
					<Typography fontFamily='Krub' fontSize='16px' color='#5C41BD' mb={1}>
						Políticas de privacidad
					</Typography>
				</Link>
			</Grid>
			<Grid item xs={2} display='flex' alignItems='end'>
				<Grid container direction='column' alignSelf='flex-start'>
					<Box mb={12} display='flex' justifyContent='space-around'>
						{/* <i
							className='icon-facebook'
							style={{
								fontSize: '25px',
								color: '#FF6651',
								marginRight: '1em',
							}}
						></i>
						<i
							className='icon-twitter'
							style={{
								fontSize: '25px',
								color: '#FF6651',
								marginRight: '1em',
							}}
						></i> */}
						<a
							href='https://www.instagram.com/deutiles.ar/'
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'unset' }}
						>
							<i
								className='icon-instagram'
								style={{
									fontSize: '55px',
									color: '#FF6651',
									marginRight: '3em',
								}}
							/>
						</a>
					</Box>
					<Box>
						<Typography
							fontFamily='Krub'
							fontSize='18px'
							fontWeight='bold'
							color='#3D363C'
							ml='4em'
						>
							Powered by
						</Typography>

						<img
							src={yoplabs}
							alt='logo-yoplabs'
							style={{
								width: '174px',
								height: '52px',
							}}
						></img>
					</Box>
				</Grid>
			</Grid>
		</Grid>
		<Grid container justifyContent='center'>
			<Grid item xs={10}>
				<div style={{ border: '6px solid #FF6651' }}></div>
			</Grid>
		</Grid>
	</Fragment>
);

export default FooterView;
