import { Failure } from 'data/types';
import {
	GetHighlightProductResponse,
	GetHighlightProductRequest,
} from 'data/types/productsTypes';
import { Either } from 'models/either';
import * as BaseRepository from 'data/models/baseRepository';
import * as BaseApi from 'data/models/baseApi';

export const getAllMicrosites = async (): Promise<
	Either<Failure, GetHighlightProductResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetHighlightProductRequest, GetHighlightProductResponse>({
			url: '/api/public/v1/microsite',
			secure: false,
		})
	);
};

const getAllMicrositesApi = {
	getAllMicrosites,
};

export default getAllMicrositesApi;
