import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: '#f9f9f9',
	},
	containerImg: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		marginTop: '5rem',
		marginBottom: '1rem',
		padding: '5rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '70%',
	},

	img: {
		maxWidth: '100%',
		width: '40%',
		height: 'auto',
	},

	containerCard: {
		padding: '5rem',
	},

	containerFooter: {
		paddingTop: '30px',
	},

	featuredProducts: {
		fontWeight: 'bold',
		fontSize: '30px',
		color: '#5C41BD;',
	},

	containerProducts: {
		display: 'flex',
		gap: '50px',
		justifyContent: 'center',
	},

	containerFeaturedProducts: {
		display: 'flex',
		gap: '50px',
		flexWrap: 'wrap',
	},

	mainContainer: {
		display: 'flex',
		gap: '50px',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	cardTitle: {
		fontFamily: 'Krub',
		fontSize: '20px',
		fontWeight: '100',
		color: '#3D363C',
	},

	cardImage: {
		width: '250px',
		height: '250px',
	},

	products: {
		justifyContent: 'center',
		border: '1px solid grey',
		borderRadius: '16px',
		width: '300px',
		height: 'max-content',
		padding: '20px',
		maxWidth: '100%',
		backgroundColor: 'white',
	},
}));
export default useStyles;
