import React, { useEffect } from 'react';
import { ViewFourWitch } from './view';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import {
	GetHighlightProductAsync,
	selectProducts,
} from 'data/global/features/ProductsSlice/productsSlice';
import {
	GetBrandsAsync,
	selectBrands,
} from 'data/global/features/BrandsSlice/brandsSlice';
import {
	GetCategoriesAsync,
	selectCategories,
} from 'data/global/features/CategoriesSlice/categoriesSlice';
import {
	GetBannersAsync,
	selectBanners,
} from 'data/global/features/BannersSlice/bannersSlice';
import { validationUser } from 'data/api/usersApi';
import { useGetMicrosite } from './hooks';
import { useParams } from 'react-router-dom';
import { selectMicrosite } from 'data/global/features/MicrositeSlice/micrositeSlice';

export const FourWitch: React.FC = () => {
	const JWT = localStorage.getItem('jwt');
	const { name } = useParams<{ name: string }>();
	const getMicrosite = useGetMicrosite();

	useEffect(() => {
		getMicrosite(name);
	}, []);

	const settingSliderProductsHighlight = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: false,
		responsive: [
			{
				breakpoint: 1220,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};

	const settingsSliderBrands = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 7,
		slidesToScroll: 7,
		arrows: false,
		responsive: [
			{
				breakpoint: 1220,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
		],
	};
	const dispatch = useAppDispatch();

	type LocationStateProps = {
		getMicrosite: any;
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const code = queryParams.get('code');

		if (code) {
			validationUser({ code });
		}
	}, [validationUser]);

	useEffect(() => {
		dispatch(GetHighlightProductAsync({}));
		dispatch(GetBrandsAsync({ showInHome: true }));
		dispatch(GetCategoriesAsync({}));
		dispatch(GetBannersAsync({}));
	}, []);

	const { GetHighlightProductResponseRequest } = useAppSelector(selectProducts);
	const { brands } = useAppSelector(selectBrands);
	const { categories } = useAppSelector(selectCategories);
	const { banners } = useAppSelector(selectBanners);
	const { micrositeDetails } = useAppSelector(selectMicrosite);
	return (
		<ViewFourWitch
			banners={banners}
			brands={brands}
			products={GetHighlightProductResponseRequest}
			categories={categories}
			settingSliderBrand={settingsSliderBrands}
			settingSliderProductsHighlight={settingSliderProductsHighlight}
			login={JWT}
			getMicrosite={getMicrosite}
			micrositeDetails={micrositeDetails}
		/>
	);
};
